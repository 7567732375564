// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api2.beddy.dev/',
  appUrl: 'https://app.beddy.dev/',
  bolUrlMask: 'https://{dns}.beddy.dev',
  quoteRequestUrlMask:
    'https://{dns}.beddy.dev/availability-request-form?widget-code={widgetcode}',
  storageUrl: 'https://beddy-dev.s3.eu-west-2.amazonaws.com/',
  cdnUrl: 'https://cdn.beddy.io/',
  guideUrl: 'https://help.beddy.io/',
  supportUrl: 'https://support.beddy.io/',
  beddyChannelId: 10,
  httpProxy: 'http://http-proxy.beddy.tech/',
  sentry: {
    dsn: 'https://40e49b28007d4a2bb3b7393bfd10cc3c@sentry.beddy.dev/6',
    debug: false,
  },
  googleApiKey: 'AIzaSyCVZTkMJOBvULmbVaGjCNowv6Joo4S1QbE',
  googleTranslateApiKey: 'AIzaSyCdQ5caVcTmxKB2SCRMBBOEXoT1thuxZzU',

  bolScripts: 'https://cdn.beddy.io/bol/dev/',
  bolV2Scripts: 'https://cdn.beddy.io/bol-v2/dev/',

  stripeConnectUrl:
    'https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_7k920mGSMSUBvpfOyZ1mzKLqkcN0wdUE&scope=read_write&redirect_uri=https://api.beddy.dev/stripe_connection',

  stripeAPIKey: 'pk_test_pfB6L6IXnvK3jvwkXknsHqqt',

  firebase: {
    apiKey: 'AIzaSyBResWDz4URk6iKKC9DUl2LfoHAVyaITH8',
    projectId: 'beddy-e4a76',
    messagingSenderId: '839969589755',
    appId: '1:839969589755:web:d96eeabc25d4eb8c01635e',
    authDomain: 'beddy-e4a76.firebaseapp.com',
    databaseURL:
      'https://beddy-e4a76-default-rtdb.europe-west1.firebasedatabase.app',
  },

  unlayer: {
    projectId: 105753,
  },
};

export const GOOGLE_APPLICATION_CREDENTIALS =
  '@app/environments/google-vision.json';
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
